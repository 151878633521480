export default {
  name: "recommendations",
  components: {},
  data() {
    return {
      recommend: [
        {
          date: new Date().toLocaleDateString(),
          text: 'Термин "космецевтика" означает, что продукт сочетает традиционные для косметики характеристики и \n' +
            'привлекательный внешний вид с терапевтическими свойствами. Строго говоря, терапевтическое действие всегда было связано с фармацевтическими продуктами. Но, термин оказался нужен для описания косметических продуктов, которые интенсивны по своему действию имеют какое-то терапевтическое преимущество по сравнению с обычной косметикой, но по совокупности признаков лекарствами не являются.',
          img: '',
        },
        {
          date: new Date().toLocaleDateString(),
          text: 'Термин "космецевтика" означает, что продукт сочетает традиционные для косметики характеристики и \n' +
            'привлекательный внешний вид с терапевтическими свойствами. Строго говоря, терапевтическое действие всегда было связано с фармацевтическими продуктами. Но, термин оказался нужен для описания косметических продуктов, которые интенсивны по своему действию имеют какое-то терапевтическое преимущество по сравнению с обычной косметикой, но по совокупности признаков лекарствами не являются.',
          img: require('../../../../../../src/assets/img/about-us/Rectangle158.jpg'),
        },
        {
          date: new Date().toLocaleDateString(),
          text: 'Термин "космецевтика" означает, что продукт сочетает традиционные для косметики характеристики и \n' +
            'привлекательный внешний вид с терапевтическими свойствами. Строго говоря, терапевтическое действие всегда было связано с фармацевтическими продуктами. Но, термин оказался нужен для описания косметических продуктов, которые интенсивны по своему действию имеют какое-то терапевтическое преимущество по сравнению с обычной косметикой, но по совокупности признаков лекарствами не являются.',
          img: '',
        },
      ]
    };
  },
  methods: {}

};
